<template>
    <div>
        <div class="page-filter-box">
            <el-row :gutter="20">
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                    <el-input placeholder="请输入关键字" v-model="params.keyword" size="small"></el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4" class="page-filter-btns">
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        @click="search"
                        size="small"
                        >查询</el-button
                    >
                    <el-button
                        type="default"
                        icon="el-icon-refresh"
                        @click="reset"
                        size="small"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </div>
        <div class="page-list-box">
            <el-table
                :data="dataList"
                border
                style="width: 100%"
                v-loading="listLoading"
                size="mini"
                stripe
            >   
                <el-table-column prop="id" label="ID"></el-table-column>
                <el-table-column prop="content" label="评论内容" min-width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="reviewer" label="评论人"></el-table-column>
                <el-table-column prop="status" label="状态"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="150"></el-table-column>
                <el-table-column fixed="right" label="操作" width="80">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="edit(scope.row)"
                            >{{scope.row.status2 == 1 ? "屏蔽" : "展示"}}</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-pagination-box clearfix">
            <div class="fl"><el-button @click="goBack()" size="small">返回</el-button></div>
            <el-pagination
                v-if="data"
                background
                class="fr"
                @size-change="handleSizeChange"
                @current-change="getDataList"
                :current-page.sync="params.pn"
                :page-sizes="[15,30,50]"
                :page-size.sync="params.ps"
                layout="total, sizes, prev, pager, next"
                :total="data.totalCount || 0">
            </el-pagination>
        </div>
    </div>
</template>
<script>
const defaultParams = {
    pn:1,
    keyword: "",
    ps:15,
}
export default {
    props:{
        id: {
            type:[Number,String],
            default:""
        }
    },
    data(){
        return {
            redirect: this.$route.query.redirect || '/fugao/list',
            data: null,
            dataList: null,
            params: {
                ...defaultParams,
                pn: parseInt(this.$route.query.pn || 1),
                keyword: this.$route.query.keyword || "",
                ps: parseInt(this.$route.query.ps || 15),
                // fugaoId: this.id
            },
            listLoading: true,
        };
    },
    mounted() {
        this.params.fugaoId = defaultParams.fugaoId = this.id;
        this.getDataList();
    },
    methods:{
        //返回
        goBack() {
            if(this.redirect){
                this.$router.push({
                    path: this.redirect,
                });
            }else{
                this.$router.go(-1);
            }
        },
        //获取列表数据
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/api/ht/fugao/review/list",
                params: this.params,
            })
                .then((res) => {
                    const { state, errmsg, result } = res.data;
                    if (state && result) {
                        this.data = result;
                        this.dataList = result.list || [];
                    } else {
                        this.$message.error(errmsg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pn = 1;
            this.params.ps = e;
            this.getDataList();
        },
        //搜索
        search() {
            this.params.pn = 1;
            this.getDataList();
        },
        //重置搜索
        reset() {
            this.params = {
                ...defaultParams,
                ps: this.params.ps,
            };
            this.getDataList();
        },
        //
        edit(data){
            const _msg = data.status2 == 1 ? '确定要屏蔽该评论吗?' : '确定要展示该评论吗?';
            this.$confirm(_msg, '提示', {
                type: 'warning',
                center: true,
                closeOnClickModal: false
            }).then(() => {
                this.listLoading = true;
                this.$request({
                    url: '/api/ht/fugao/review/hide',
                    method: 'POST',
                    params: {reviewId:data.id, status: data.status2 == 1 ? 0 : 1}
                }).then(res => {
                    const { state, msg } = res.data;
                    if( state == 1 ){
                        this.getDataList();
                    }else{
                        this.$message.error(msg || '请求失败');
                    }
                }).catch(() => {
                    this.$message.error('请求错误');
                }).finally(() => {
                    this.listLoading = false;
                });
            }).catch(() => {})
        }
    }
}
</script>